import { Component } from 'react';
import '../resources/style/footer.css';

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <p>LIVEOILPRICES</p>
            </div>
        )
    }

}

export default Footer;